<template>
  <div id="policyTsft" >
     <div class="about" id="warp">
      <div class="company-details">
        <div class="container">
          <div class="privacy_policy">
            <p> 本公司（下称“交易商”）根据《个人资料（隐私）条例》来保护客户的个人资料隐私。在交易商使用客户资料以提供其最佳之服务时，亦会高度关注对于客户资料使用之安排。交易商会根据相关法例《个人资料（隐私）条例》之规定，力求完全符合个人资料保证要求。为此，交易商会确保其雇员遵守法例规定的保安和保密严谨标准。 </p>
            <p>
              <span>1、</span>
              <span>为保障客户正常使用服务，维护服务的正常运行，改进及优化服务体验以及保障账户安全，可能需要客户授权使用如下个人权限： <br />
                <b style="color: red">*实名认证：确保客户信息准确无误</b>
                <br />
                <b style="color: red">*相机/相册：发送图片，选取图片上传，存储照片，扫一扫，用于反馈意见和个人信息审核时使用</b>
                <br />
                <b style="color: red;">*用户地址：主要用于发放实物奖励</b>
                <br />
                <b style="color: red;">*摄像头：更换头像点击上传照片</b>
                <br />
                <b style="color: red;">*读取本机电话号码：精准投放相应营销广告信息</b>
                <br />
                <b style="color: red;">*录音：用户在实名认证时需要获取权限</b>
                <br />
                <b style="color: red;">*查看WLAN连接：投放广告</b>
                <br />
                <b style="color: red;">*检索正在运行的应用：判断用户手机运行内存</b>
                <br />
                <b style="color: red">*银行卡识别：为保障客户资金安全，需进行银行卡审核</b>
                <br />
                <b style="color: red">*读取外置储存卡：获取一键登录信息，号码认证的功能</b>
                <br />
                <b style="color: red;">*写入外部存储卡：精准推进投放广告</b>
                <br />
                <b style="color: red;">*访问定位额外指令：用于发放实物奖励</b>
                <br />
                <b style="color: red">*读取手机状态和身份：获取一键登录信息，号码认证的功能</b>
                <br />
                <b style="color: red">*自启动：为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启自启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置 - 应用 - 应用启动管理 - 选择应用“{{appName}}”- 关闭关联启动功能。</b>
                 <br />
              </span>
              
            </p>
<p>您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置、电话状态、麦克风（语音）、通讯录的访问权限，以实现这些权限所涉及信息的收集和使用。
您可在您的设备设置逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。
请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
            <p>
              <span>2、</span>
              <span>交易商收集的各项个人资料，只供应业务运作及其他有关活动之用；</span>
            </p>
            <p>
              <span>3、</span>
              <span>      <b>关于使用Cookies技术：交易商可能会在客户及存款交易商的电脑设定cookies，可能会于交易商的产品和服务中使用cookies来分析客户在交易商网站的浏览行文，以协助交易商更好的了解用户需求及提供更好的服务方式，但对于收集得来和共用的资料是不具姓名及无法被个别识别的；      </b></span>
            </p>
            <p>
              <span>4、</span>
              <span>信息储存：交易商会按照法律法规规定，将收集的用户信息存储于相应服务器。一般而言，我们仅为实现对客服务所必需的时间保留客户的用户信息。</span>
            </p>
            <p>
              <span>5、</span>
              <span><b>读取已安装包名列表：依据法律法规及监管规定，本公司会在处于后台运行或静默状态时读取已安装包名列表判断本应用是否在后台运行，并且只会从列表中筛选出本应用-<span v-if="!spJY">{{appName}}</span>，确保用户其他信息不会泄露。</b></span>
            </p>
            <p>
              <span>6、</span>
              <span>信息安全保护：交易商致力于使用防火墙、密码和个人身份证号码认证系统和操控机制来控制未授权的系统进入和存款资料，或使用其他资料保障工具确保网站安全及符合业界标准；</span>
            </p>
            <p>
              <span>7、</span>
              <span>交易商不会将客户个人资料用于用户画像，也不会收集关于您设备上安装的其他APP信息。如交易商使用客户的用户信息，超出了与收集时声称的目的及具有直接或合理关联的范围，将会在使用用户信息前，再次告知并征求客户的明示同意（通过主动点击“确定”做出直接性动作。）</span>
            </p>
            <p>
              <span>8、</span>
              <span>交易商会将客户个人资料保密储存，防止其个人资料被盗用，为达到隐私保护之最佳效果，除符合本款第五、第七条之规定外，客户充分理解交易商不会向客户本人以外的任何主体提供客户的资料、信息，即使该主体声称其为授权方，交易商亦无审核其授权合法性之能力与义务；</span>
            </p>
            <p>
              <span>9、</span>
              <span>交易商将尽一切所能确保其收集所得的客户个人资料被妥善地保存在储存系统，个人资料将留存在储存系统中5年，超过存储期限后，用户未声明销毁的情况下，公司将自动延长5年存储期限；若用户声明销毁，公司将立即按照用户声明将个人资料进行销毁处理。只有获授权的雇员或向交易商提供服务的公司可优先地查询有关资料，但鉴于互联网之公开及全球性质，若客户经由交易商网页浏览至他人运作的网页或因客户自身操作不当泄露的信息，客户的个人资料均不属交易商的个人资料隐私条款保障范围内。</span>
            </p>
            <p>
              <span>10、</span>
              <span>客户同意交易商可能授权第三方使用客户个人资料，这些包括以合约形式向交易商提供服务的公司。比如，在您允许推送的前提下，您的设备MAC地址个人信息有可能会提供给第三方如北京锐讯灵通科技有限公司、极光推送、极光认证、友盟等这类公司，用于协助信息的传达，
本应用中集成的第三方SDK在静默状态下或在后台运行时，会收集用户的网络权限、定位权限、获取手机状态码权限、读写权限、wifi权限，我们承诺不会将您的个人信息泄露或挪为他用。</span>
            </p>
  <p>
“第三方信息共享”条款之授权与同意：
</p>
<p>
（1）针对极光推送SDK产品收集和使用最终用户个人信息的类型和目的说明：
</p>
<p style="text-indent:2em"> 1)客户同意本公司向第三方极光推送SDK产品收集提供有关您的个人信息包括：
<p style="text-indent:2em;font-weight:bold;">
①获取您的个人设备信息:用于识别用户的设备类型、设备型号、系统版本以及设备序列号与软件安装列表，确保消息准确下发。
</p>
<p style="text-indent:2em;font-weight:bold;">
 ②设备标识符： 用于识别唯一用户，保证推送的精准送达及推送信息的准确统计。
</p>
<p style="text-indent:2em;font-weight:bold;">
 ③网络信息与位置信息 ：用于优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性。
</p>
<p style="text-indent:2em;font-weight:bold;">
 以上获取之用户个人信息仅在符合法律法规规定的范围内使用并仅用作推送消息用途。 
</p>
<p style="text-indent:2em;font-weight:bold;">
 设备信息：设备标识符（IMEI、IDFA、Android ID、MAC、OAID的话相关信息）以及设备序列号；
</p>
<p style="text-indent:2em;font-weight:bold;"> 信息：应用崩溃信息、通知开关状态、APP应用列表及活跃状态、软件列表相关信息、APP应用页面信息、APP功能事件相关信息；</p>
<p style="text-indent:2em;font-weight:bold;">设备参数及系统参数：设备类型、设备型号、系统版本、操作系统及硬件相关信息、及相关硬件信息；</p>
<p style="text-indent:2em;font-weight:bold;">网络信息：IP地址、WiFi信息、基站与传感器信息、DNS地址、DHCP地址、SSID、BSSID。</p>
<p style="text-indent:2em;font-weight:bold;">位置信息：经纬度。</p>
<div style="text-indent:2em">
  2）第三方极光推送会采用替换、去标识化或匿名化方式收集、存储、加工来自于您的应用程序的终端用户的个人信息进行处理和脱敏展示，用于为您提供服务提醒、订阅消息推送等。
</div>
<p style="text-indent:2em;font-weight:bold;"> 如有任何疑问可联系”support@jiguang.cn” 。</p>
<p>
  （2）针对极光认证SDK等产品收集和使用最终用户个人信息的类型和目的说明：客户同意本公司向第三方极光认证SDK（包含中国电信、中国移动、中国联通一键登录SDK）产品收集提供有关您的个人信息包括：
</p>
<p style="text-indent:2em">
<b>
①设备参数、系统信息（设备类型、设备型号、操作系统及硬件相关信息）及设备标识符(IMEI、IDFA、Android ID、MAC、OAID的话相关信息)：用于检验用户账号和行为安全，判断用户行为是否为机器程序批量自动化操作。
</b>
</p>
<p style="text-indent:2em">
<b>
 ②网络信息与位置信息 ：用于优化极光SDK与极光服务器的网络连接请求，分析安全认证服务请求失败原因，保证服务的稳定性和连续性；
</b>
</p>
<p style="text-indent:2em">
<b>
  ③ 手机号码 ：用于完成一键登录和号码认证、识别您终端用户的风险。
</b>
</p>
<p>
  以上获取用户个人信息仅在符合法律法规规定的范围内使用并仅用作App一键登录，号码认证的功能用途。 
</p>
<div>
 极光认证SDK更多隐私政策请参看：<b>https://www.jiguang.cn/license/privacy </b>
</div>
<p>
  （3)针对友盟SDK等产品收集和使用最终用户个人信息的类型和目的说明：客户同意本公司向第三方友盟推送SDK等产品收集提供有关您的个人信息包括：
</p>
<p>
  ①获取用户设备的IMEI，通过IMEI对用户进行唯一标识，以便提供服务。
</p>
<p>
  ②通过获取用户位置信息，为开发者提供反舞弊功能，剔除作弊设备；同时校正用户的地域分布数据，使报表数据更加准确。
</p>
<p>
  以上获取之用户个人信息仅在符合法律法规规定的范围内使用。
</p>
<p>
  详情请点击并阅读友盟隐私政策：<br/>
  https://www.umeng.com/page/policy，如有任何疑问可联系：Umeng_Legal@service.umeng.com。
</p>

            <p>
              <span>11、</span>
              <span>客户同意交易商可根据任何法例或监管机构之规定，向监管当局及其他政府机构披露客户所有资料；除此之外，交易商不会对外公开披露其收集的用户信息，如必须公开披露时，交易商会向您告知此次公开披露的目的、披露的信息类型及可能涉及的敏感信息，并征得客户的明示同意。</span>
            </p>
            <p>
              <span>12、{{spContent}}</span>
              <span></span>
            </p>
            <p>
              <span>13、</span>
              <span>如客户对本用户信息政策相关事宜有任何问题、意见或建议，请联系客服。</span>
            </p>
            <p>
              <span>14、</span>
              <span>本条款生效时间为2010年- <span class="policy-year">2026</span>年。如国家法律法规变化、交易商业务功能变更、个人信息处境情况变更、使用目的变更、个人信息保护相关负责人联络方式变更，交易商会对本条款及相关规则进行修改，修改内容会通过APP站内推送，让客户知悉并确认。 </span>
            </p>
            <!-- <p>
              <span>15、</span>
              <span> 公司地点：香港九龙尖沙咀； 客服邮箱： <a href="mailto:cs@rlcbroker.com" style="color: #515151">cs@rlcvipltd.net。</a>
              </span>
            </p> -->
            <!-- <p>交易商将尽一切所能确保其收集所得的客户个人资料被妥善地保存在储存系统，并只有获授权的雇员或向交易商提供服务的公司可有限地查阅有关资料，但鉴于互联网之公开及全球性质，交易商可能无法保护及保证客户个人资料完全不受第三者的恶意干扰。凡经由交易商网页浏览他人运作的网页，客户的个人资料均不属交易商的个人资料私隐条款保障范围内。</p> -->
            <br />
            <br />
            <p style="
                font-style: italic;
                color: #a29d9d;
                font-size: 12px;
                text-align: center;
                display: block;
              "> 本隐私政策条款，更新于2024年9月1日 </p>
            <p v-if="!spJY">开发者名称：{{companyName}}</p>
            <p v-if="!spJY">应用名称：{{appName}}</p>
            <p v-if="!spJY">应用版本号：{{version}}</p>
          </div>
        </div>
      </div>      

    </div>

  </div>
</template>

<script>
import eventBusMitter from '../../../utils/eventbus';
export default {
  name: 'policyTsft',
  data(){
    return{
      spJY:false,
      companyName:'香港红狮国际期货投资有限公司',
      version:'V2.6.1',
      appName:'红狮国际',
      channel:'',
      spContent:'客户可不时查询及更改由交易商所保存的有关个人资料；客户如需删除个人信息、注销账户或撤回已同意的授权，可联系客服核实处理，前述指示将在10个工作日内完成。客户的账号注销完成后，公司将立即销毁用户的个人信息。'
    }
  },
  created(){
    // 接收eventBus传来的值
    // eventBusMitter.on('eventBus',(res)=>{
    //   this.control = res.key;
    // })
    this.channel = this.$route.query.channel
    this.appName = this.$route.query.appName?this.$route.query.appName :'红狮国际'
     this.version =  this.$route.query.version? this.$route.query.version :'V2.6.1'
    switch(this.channel){
      case '9901':
        this.companyName='成都莎亚馨伟科技有限公司 '
        break
      case '9920':
        this.companyName='成都莎亚馨伟科技有限公司'
        break
      case '9923':
        this.companyName='成都莎亚馨伟科技有限公司'
        break
      case '9902':
         this.companyName='成都依荣布科技有限公司'
        break
      case '9903':
      case '9907':
      case '9917':
      case '9908':
      case '9922':
      case '9906':
      case '9999':
      case 'YFJY7001':
      case 'YFJY7002':
      case 'YFJY7003':
      case 'YFJY7004':
      case 'YFJY7005':
      case 'YFJY7006':
      case 'YFJY7007':
      case 'YFJY7008':
      case 'YFJY7009':
      case 'YFJY7010':
         this.companyName='香港红狮集团有限公司'
        break
      case '9801':
      case '9802':
      case '9820':
      case '9823':
      case '9925':
         this.companyName='上海前青信息科技有限公司'
        break
      case '99461':
         this.companyName='辽宁省黄星网络科技有限公司'
        break
      case 'HFTZ7006':
         this.companyName='山东齐盛期货有限公司'
      break
      case 'FTTZ006':
      case 'HSFT6002':
         this.companyName='渤海汇金证券资产管理有限公司'
      break
      case 'HFTY7001':
      case 'HFTY7002':
         this.companyName='冠通期货股份有限公司'
      break
      case 'HSFT0001':
      case 'HSFT0004':
      case 'HSFT0007':
         this.companyName='昆明昱炯网络科技有限公司'
      break
      case 'YKFF001':
      case 'YKFF002':
      case 'YKFF003':
      case 'YKFF004':
      case 'YKFF005':
      case 'YKFF006':
      case 'YKFF007':
      case 'YKFF008':
      case 'YKFF009':
      case 'YKFF010':
         this.companyName='深圳市达诚信网络科技有限公司'
      break
      case 'HSQF9999':
      case '9948':
      case '9910':
      case '9915':
      case '9931':
      case '9911':
      case '9912':
      case '9934':
      case '9932':
      case '9938':
      case '9909':
      case '9918':
      case '9921':
      case '9935':
      case '9936':
      case '9919':
      case '9941':
      case '9942':
      case '9943':
      case '9933':
      case '9946':
      case '9947':
         this.companyName='广州铭递科技有限公司'
      break
      case 'HSGJ2024':
         this.companyName='香港红狮国际期货投资有限公司'
      break
      case '1003':
         this.spContent ='客户如需删除个人信息、注销账户，可在【我的】-【APP设置】-【注销账户】进入后联系客服核实处理，公司将会在10个工作日内完成客户的账户注销，并立即销毁用户的个人信息。'
          this.spJY = true
        break
    }
     eventBusMitter.emit('eventBus', {key:true})
  },
  mounted() {
    document.title = '隐私条款'
  },
  methods:{

  }
}
</script>

<style lang="less" scope>

      @charset "utf-8";
      a,
      button {
        -webkit-appearance: none;
        border-radius: 0;
        border: none;
        outline: 0;
      }

      body * {
        font-family: "microsoft yahei", Helvetica;
      }

      body {
        -webkit-text-size-adjust: 100%;
        background: #e8e8e8;
        margin: 0;
        padding: 0;
        -webkit-user-select: none;
        color: #515151;
        font-size: unset;
      }

      div,
      h1,
      p,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      li,
      span,
      input,
      p,
      a,
      table,
      th,
      tr td {
        margin: 0;
        padding: 0;
      }

      html,
      body {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }


      .contact_item_cont ::-webkit-input-placeholder {
        color: #ccc;
      }

      .contact_item_cont ::-moz-placeholder {
        color: #ccc;
      }

      .contact_item_cont :-ms-input-placeholder {
        color: #ccc;
      }

      .contact_item_cont input:-moz-placeholder {
        color: #ccc;
      }

      .container {
        padding: 0 4%;
      }

      .fl {
        float: left;
      }

      .fr {
        float: right;
      }

      .fix:after {
        content: "";
        display: block;
        clear: both;
      }

      .fix {
        zoom: 1;
      }

      .hide {
        display: none !important;
      }

      .text-left {
        text-align: left;
      }

      .text-right {
        text-align: right;
      }


      /**主体内容**/
      #warp {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }

      #warp.navpanel-open {
        width: 100%;
        position: fixed;
        overflow: hidden;
      }

.company-details {
  font-size: 12px;
  color: #515151;
  padding: 1rem 0;
  line-height: 24px;
  font-size: 12px;
}

.company-details p {
  line-height: 20px;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.privacy_policy p span {
  /* display: inline-block;
    vertical-align: top; */
}
#policyTsft{
  font-size: 12px!important;
}
</style>
